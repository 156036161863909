@import "_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/ngx-toastr/toastr.css";

html {
  position: relative;
  min-height: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  height: 100%;
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: normal;
}

a {
  cursor: pointer;
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.semilight {
  font-weight: 300;
}

// Override Bootstrap button focus
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.modal-backdrop {
  background: rgba(29, 29, 29, 0.15);
  backdrop-filter: blur(0.333333333rem);
}

.modal-backdrop.show {
  opacity: 1;
  z-index: 1040 !important;
}

.modal-content {
  box-shadow: $shadow-box;
  border-radius: $border-radius-ppx;
  border: 0rem;
  background-color: $modal-background;
  width: inherit;
  min-width: inherit;
  margin: auto;
  z-index: 1100 !important;
}

.margin-left-for-cancellation-reasons-modal {
  margin-left: 1.2rem;
}

.modal-header {
  justify-content: flex-start;
  border-bottom: 0rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal-body {
  padding-bottom: 2rem;
  padding-top: 0rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal-title {
  font-weight: normal;
  font-size: 1.458333333rem;
  line-height: 1.958333333rem;
}

.modal-close {
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  content: url(../src/assets/modal/x.png);

  &.x-icon-white {
    content: url(../src/assets/icons/buttons/x-icon-white.png);
    height: 1.3rem;
    width: 1.3rem;
    right: 0.75rem;
  }
}

.modal-data-label {
  font-size: 0.625rem;
  font-weight: 700;
  text-align: center;
  color: #4E4E4E;
  background-color: #F5F5F5;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  line-height: 0.8333rem;
}

.modal-data {
  font-size: 0.83125rem;
  font-weight: 400;
  text-align: center;
  color: #006F11;
  background-color: white;
  padding-top: 0.208333333rem;
  padding-bottom: 0.208333333rem;
}

.modal-data-spaced {
  margin-top: 0.833333333rem;
  margin-bottom: 0.833333333rem;
}

.modal-data-left-col {
  border-right: 0.041666667rem solid #CDCFD6;
}

.modal-p1 {
  font-size: 1.083333333rem;
  font-weight: 400;
  color: #4E4E4E;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-small-text {
  font-size: 0.625rem;
  font-weight: 400;
  color: #4E4E4E;
}

.modal-button.invalid {
  background: #a6abbd !important;
  color: #717171 !important;
  cursor: auto;
}

.modal-button.loading {
  color: transparent !important;
}

.modal-button {
  border: none;
  font-weight: 600;
  padding: 0.4981320627568rem 1.245330156892rem;
  color: white;
  border-radius: $border-radius-ppx;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  display: block;

  span {
    float: left;
  }

  &:hover {
    color: white;
  }
}

.modal-button::after {
  content: '';
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  position: absolute;
  top: calc(50% - 1.17rem);
  left: calc(50% - 1.17rem);
  border: 0.15em solid transparent;
  border-right-color: white;
  border-radius: 50%;
  animation: button-anim 0.7s linear infinite;
  opacity: 0;
  color: transparent;
}

.modal-button.loading::after {
  opacity: 1;
}

@keyframes button-anim {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.receipt-img {
  height: 1.333rem;
}

.button-image-offset {
  left: 1.333333333rem !important;
}

.modal-button-left {
  padding-right: 0.5rem;
}

.modal-button-right {
  padding-left: 0.5rem;
}

.button_red {
  color: white;
  background-color: #B01D13;
  border-color: #B01D13;

  &:hover {
    color: white;
    background-color: #B01D13;
    border-color: #B01D13;
    text-decoration: none;
  }
}

.button_green {
  color: white;
  background-color: #02A844;
  border-color: #02A844;

  &:not(:disabled):active {
    color: white !important;
    background-color: #017630 !important;
    border-color: #017630;
  }

  &:hover {
    color: white;
    background-color: #02A844;
    border-color: #02A844;
    text-decoration: none;
  }
}

.button_grey {
  color: #747474;
  background-color: #A6ABBD !important;
  border-color: #A6ABBD !important;

  &:hover {
    color: #747474;
    background: #A6ABBD;
    border-color: #A6ABBD;
    text-decoration: none;
  }
}

.button_black {
  color: #4E4E4E;
  border-color:#A6ABBD;
  border-width: 1px;
  border-style: solid;
}

.upload-btns {
  color: #4E4E4E;
  background: none;
  border: 1px solid #A6ABBD;
  border-radius: $border-radius-ppx;

  &:hover {
    color: #747474;
  }
}

.button_clear {
  color: #4E4E4E;
  border: 0.067rem solid #A6ABBD;

  &:hover {
    color: #4E4E4E;
    border-color: #A6ABBD;
    text-decoration: none;
  }
}

.button_clear::after {
  border-right-color: #4E4E4E;
}

.rounded-edge {
  border-radius: $border-radius-ppx;
}

.bold {
  font-weight: 700;
}

.white-fill {
  background-color: white;
  height: 100%;
}

.pad-right {
  margin-right: 1rem;
}

.pad-left {
  margin-left: 1rem;
}

.pad-top {
  margin-top: 1rem;
}

.pad-bottom {
  margin-bottom: 1rem;
}

.main-page {
  background-color: $main-background;
  display: flex;
  flex-flow: column;
  height: 100%;
}

@media(max-width: 992px) {
  .main-page {
    padding: 0;
  }
}

.main-page-padding {
  padding-top: 0.6667rem;
  padding-bottom: 0.6667rem;
}

.page-container {
  overflow: auto;
  height: calc(100vh - 2.4rem);
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(calc(var(--vh, 1vh) * 100) - 1.8rem);
  display: flex;
  flex-flow: column;
}

.page-title {
  border-radius: $border-radius-ppx;
  background-color: $white;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .buttons {
    margin-left: auto;
    margin-right: 0;
  }
}

.page-header {
  font-size: 1.45rem;
  font-weight: 700;
  line-height: 1.95rem;
  color: #4E4E4E;
}

.page-subheader {
  font-size: 1.45rem;
  font-weight: 400;
  line-height: 1.95rem;
  color: #4E4E4E;
}

.page-content {
  border-radius: $border-radius-ppx;
  background-color: $white;
  flex: 0 1 auto;
  height: 100%;
  position: sticky;
  padding: 0.625rem;
}

.page-content-after-header {
  margin: 0.625rem 0;
}

.replace-padding {
  padding-left: 0rem;
  padding-right: 0rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.content {
  flex: 1 0 auto;
}

.version-number {
  font-size: 0.458333333rem;
  height: 0.833333333rem;
  width: 4rem;
  background-color: $main-background;
  bottom: 0rem;
  position: absolute;
  right: 1rem;
}

.version-number-side-menu {
  background-color: transparent;
  left: -2.7rem;
  font-size: 0.458333333rem;
  height: 0.833333333rem;
  position: absolute;
  bottom: 0rem;
}

.link {
  cursor: pointer;
}

.letter-logo {
  max-width: 10.66666667rem;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-block {
  width: 100%;
}

.appointment-details-btn {
  @extend .btn;
  border-color: #4E4E4E;
  margin-right: 1rem;
}

input,
select {

  &.ng-touched,
  &.ng-dirty {
    &.ng-valid {
      border-color: $green;
    }

    &.ng-invalid {
      border-color: $red;
    }
  }
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.list-button {
  border-width: 0.083333333rem;
  border-color: $gray-40;

  &.active {
    border-width: 0.125rem;
    border-color: $green;
  }

  &.invalid {
    border-color: $red;
  }

  input {
    display: none;
  }
}

.input-error {
  position: absolute;
  display: inline-block;
  right: 0.5rem;
  color: $red;
  font-size: 0.541666667rem;
  padding-top: 0.333333333rem;
  z-index: 10;

  &.more-left {
    right: 2rem;
  }
}

.info-highlight {
  background-color: rgba($yellow, 0.5);
}

.strikethrough {
  text-decoration: line-through;
}

.customerGuaranteeCheckbox {
  margin-top: 0.1875rem;
  margin-left: 1rem;
  width: 1.25rem;
  height: 1.25rem;
}

.customerGuaranteeRow {
  display: flex; 
  margin-top: 1rem
}

#invoiceterms>p,
#invoiceterms>div,
li,
#vatterms>p,
#vatterms>div {
  font-size: 0.8rem;
}

#invoiceterms>h5,
#vatterms>h5 {
  font-size: 1rem;
}

.invoiceTitle>h3 {
  text-align: center;
  padding-top: 1.5rem;
}

.invoiceSubHeading {
  padding-top: 1rem;
}

.invoiceDate {
  text-align: right;
}

.invoiceTable {
  width: 100%;
}

.appraisalTable {
  margin-top: 0.3rem;
  width: 100%;
  text-align: center;
  border-spacing: 0;

  .header {
    background: linear-gradient(0deg, rgba(2, 168, 68, 0.15), rgba(2, 168, 68, 0.15)), #FFFFFF;
    font-weight: 700;
  }

  .header-purple {
    background: linear-gradient(0deg, rgba(108, 19, 176, 0.15), rgba(108, 19, 176, 0.15)), #FFFFFF;
    font-weight: 700;
  }

  .cell {
    padding: 0.5rem 0;
    font-size: 0.625rem;
    word-wrap: break-word;
  }
}

.header-title {
  font-size: 0.8333rem;
  font-weight: 700;
  line-height: 1.125rem;
  margin-bottom: 0;
  padding-left: 0.0833rem;
}

.group {
  page-break-inside: avoid;
}

.subheading {
  font-weight: 400;
}

.selfBillingAgreementList>li {
  font-size: 1rem;
}

.largeThumbnail {
  max-width: 10.66666667rem;
  max-height: 10.66666667rem;
  cursor: pointer;
}

.mediumThumbnail {
  max-width: 5.333333333rem;
  max-height: 5.333333333rem;
  cursor: pointer;
}

.summaryImage {
  text-align: center;
}

.hidden {
  display: none;
}

.flex-container {
  display: flex;
}

.child-stretch {
  align-items: stretch;
}

.flex-grow {
  flex-grow: 1;
}

.modal-appointment-details {
  position: fixed;
  width: 18.25rem;
  top: 4rem;
  right: 18rem;
}

.modal-backdrop-appointment-details {
  opacity: 0;
  backdrop-filter: none;

  &.show {
    opacity: 0;
  }
}

.accordion-button {
  background-color: rgba(0,0,0,.03);
  color: #007bff;
  font-size: 1.5rem;
  font-weight: 500;

  &.active {
    background-color: rgba(0,0,0,.03);
    color: #007bff;
  }

  &::after {
    display: none;
  }
}

.accordion-button:not(.collapsed) {
  background-color: rgba(0,0,0,.03);
  color: #007bff;
  box-shadow: none;
}

.table-sm td {
  padding: 0.3rem;
}

.btn-success, .btn-primary {
  color: white;

  &:hover, 
  &:active,
  &:focus {
      color: white
  }
}

// start of - angular material styling override

.mat-calendar-header button.mat-calendar-period-button {
  text-transform: lowercase;
}
.mat-calendar-header button.mat-calendar-period-button span:first-letter {
   text-transform: uppercase
}
.mat-calendar-body-cell-content {

  &.mat-focus-indicator {
      &:hover {
          background-color: #E1F1E4 !important;
      }
  }

  &.mat-calendar-body-selected {
      background-color: #02A844 !important;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color:white !important;
  --mdc-filled-text-field-active-indicator-height: 0;
  --mdc-filled-text-field-focus-active-indicator-height: 0;
}
.mdc-text-field {
  border: none;
  outline: none;
}
.mat-form-field {
  height: 100%;
}
.mat-mdc-form-field {
  height: calc(100% - 0.125rem) !important;
  width: calc(100% - 0.125rem) !important;
  border-radius: $border-radius-ppx;
}
.mat-mdc-form-field-flex {
  height: 100% !important;
  width: 100% !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}
.mat-mdc-form-field-subscript-wrapper {
  height: 0rem !important;
  width: 100% !important;
}
.mdc-floating-label {
  font-size: 0.938rem !important;
  color: #4d4d4d !important;
  margin-top: -0.25rem;
}
.mdc-floating-label--float-above {
  font-size: 0.46875rem !important;
  line-height: 1.25rem !important;
  margin-top: -0.5rem;
}
.mat-mdc-form-field-input-control {
  font-size: 1.45833rem !important;
}
.mat-mdc-button-persistent-ripple, .mat-mdc-button-ripple {
  display: none;
}
.mat-mdc-form-field-infix {
  padding-top: 0.5rem !important;
}
.cdk-overlay-container {
  z-index: 2000 !important;
}

// end of - angular material styling override
