// Color Variables
// Bootstrap Color Defaults
$white: #ffffff !default;
$gray-10: #f8f9fa !default;
$gray-20: #e9ecef !default;
$gray-30: #dee2e6 !default;
$gray-40: #ced4da !default;
$gray-50: #adb5bd !default;
$gray-60: #868e96 !default;
$gray-70: #495057 !default;
$gray-80: #343a40 !default;
$gray-90: #212529 !default;
$black: #000 !default;

$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;

$green-haze: #00a94e;
$grey-slate: #a6abbd;

$main-background: #f5f5f5;
$modal-background: #e9e9e9;
$font-family: 'Segoe UI', sans-serif;

$shadow-box: 0rem 0.75rem 1.208333333rem rgba(0, 0, 0, 0.13),
  0rem 0.3796875rem 0.526758333rem rgba(0, 0, 0, 0.08775),
  0rem 0.15rem 0.196354167rem rgba(0, 0, 0, 0.065),
  0rem 0.0328125rem 0.069856667rem rgba(0, 0, 0, 0.04225);
$border-radius-ppx: 0.208333333rem;
$selected-background: linear-gradient(
    0deg,
    rgba(2, 168, 68, 0.15),
    rgba(2, 168, 68, 0.15)
  ),
  #ffffff;
